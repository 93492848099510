import Shepherd from 'shepherd.js'

export default {
    data() {
        return {
            tour: null,
            tourEvents: ['complete', 'close', 'cancel']
        }
    },
    created() {
        Shepherd.on('start', () => {
            window.Intercom('shutdown')
        })
        this.tourEvents.forEach(event => {
            window.Intercom('boot', {
                app_id: 'lliic4ws'
            })
            Shepherd.on(
                event,
                () => {
                    this.tour = null
                    this.$store.commit('setUserMetadata', { [this.tourId]: this.tourHidden, new_user: false })
                },
                this.tourId
            )
        })
    },
    beforeDestroy() {
        this.tourEvents.forEach(event => Shepherd.off(event, this.tourId))
    }
}
